<script>
// import Intersect from "vue-intersect";
export default {
  name: "account",

  metaInfo() {
    return {
      title: "Cuenta",
    };
  },

  components: {
    // Intersect,
    accountMenu: () =>
      import(
        /* webpackChunkName: "account-menu" */ "@/components/account-menu"
      ),
  },

  data() {
    return {
      sidebarOpen: false,
    };
  },

  watch: {
    $route() {
      this.sidebarOpen = false;
    },
  },
};
</script>

<template>
  <sitio-layout id="account">
    <div
      class="app__view account__layout"
      :class="{ 'sidebar--open': sidebarOpen }"
    >
      <div id="sidebar">
        <accountMenu id="sidebar__menu" />
      </div>

      <router-view class="view-area" />

      <div
        id="sidebar-scrim"
        role="button"
        tabindex="0"
        @click="sidebarOpen = !sidebarOpen"
      ></div>
    </div>
  </sitio-layout>
</template>

<style lang="scss" scoped>
/deep/ .primaryNav__content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: none;
}
.account__layout {
  padding: 0;

  #sidebar {
    background-color: #fff;
    width: 16rem;
    > * {
      padding: 0.875rem var(--sitio-ui-gap-size);
    }
    /deep/ #sidebar__menu {
      .menu {
        .menu-list {
          font-weight: 500;
        }
        .icon {
          margin-right: 1rem;
        }
      }
    }
  }

  // .view-area {
  //   position: relative;
  //   flex: 1 1 auto;
  //   display: flex;
  // }

  /deep/ .subview {
    // padding-bottom: calc(3 * var(--sitio-ui-gap-size));
    // overflow-y: auto;
    // flex: 1 1 auto;
    > * {
      padding: 0 var(--sitio-ui-gap-size);
    }
  }

  /deep/ .section {
    padding: 0;
    margin: 0 0 3rem;
  }

  /deep/ .ibox {
    padding: 1.5rem;
    box-shadow: inset 0 0 0 1px rgba(12, 18, 28, 0.12);
    border-radius: 4px;
    margin: 1rem 0;
    .ibox__title {
      font-weight: 700;
    }
    .da__label {
      font-weight: 500;
      color: #333;
    }
    // .da__value {
    //   font-weight: 500;
    //   opacity: 0.75;
    // }
  }
}

@media (max-width: 63.9375em) {
  .isSingle {
    .account__layout #account_listings,
    /deep/ #app-bar-mobile {
      display: none;
    }
    .app__view {
      padding-bottom: unset !important;
    }
  }
}

@media (max-width: 52.4375em) {
  .account__layout {
    #sidebar {
      position: fixed;
      bottom: 0;
      top: 0;
      z-index: 100;
      background-color: #fff;
      visibility: hidden;
      transform: translate3d(-256px, 0, 0);
      transition: transform 0.3s cubic-bezier(0.4, 0, 1, 1),
        visibility 0s ease 0.3s;
    }
    #sidebar-scrim {
      background: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      z-index: 50;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0s ease 0.3s;
    }
  }
  .sidebar--open {
    #sidebar {
      transform: translate3d(0, 0, 0);
      visibility: visible;
      transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    #sidebar-scrim {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease;
    }
  }
}

@media (min-width: 52.5em) {
  .account__layout {
    display: flex;
    flex-direction: row;
    #sidebar {
      flex: 0 0 16rem;
      border-right: 1px solid #ecf0f4;
    }
    .sidebarToggle {
      display: none;
    }
    .userBtn {
      .nickname {
        display: block;
      }
    }
  }
  .view-area {
    flex: 1 1 auto;
    overflow-y: auto;
    position: relative;
    /deep/ .subview {
      padding-bottom: calc(3 * var(--sitio-ui-gap-size));
      overflow-y: auto;
      max-height: 100%;
      > * {
        padding: 0 var(--sitio-ui-gap-size);
      }
    }
  }

  /deep/ .account__content {
    position: relative;
    flex: 1 1 auto;
    // > .loading-overlay {
    //   position: absolute;
    // }
  }
}
</style>
